<script lang="ts">
export default {
  inheritAttrs: false,
};
</script>

<script setup lang="ts">
import SvgIcon from '../SvgIcon.vue';

const emits = defineEmits(["update:modelValue"]);
const props = defineProps({
  modelValue: {
    type: Boolean,
    required: false,
  },
  textConfirm: {
    type: String,
  },
  reverse: {
    type: Boolean,
  },
  nameIcon: {
    type: String,
  },
  reverseIcon: {
    type: Boolean,
    default: false
  }
});

enum BUTTON_STEPS {
  "READY" = 0,
  "LOADING" = 1,
  "ENDED" = 2,
}

const steps = ref(BUTTON_STEPS.READY);
const startTime = ref(0);
const endTime = ref(0);

function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

function start() {
  startTime.value = performance.now();
}

function end() {
  endTime.value = performance.now();
  return endTime.value - startTime.value; // in ms
}

async function clickButton() {
  if (steps.value === BUTTON_STEPS.READY) {
    start();
    emits("update:modelValue", true);
    steps.value = BUTTON_STEPS.LOADING;
  }
}

watch(
  () => props.modelValue,
  async (newVal, oldVal) => {
    if (
      newVal === false &&
      oldVal === true &&
      steps.value === BUTTON_STEPS.LOADING
    ) {
      const timeDiff = end();

      if (timeDiff < 1000) await sleep(1000 - timeDiff);

      steps.value = BUTTON_STEPS.ENDED;
      await sleep(1000);
      steps.value = BUTTON_STEPS.READY;
      emits("update:modelValue", false);
    } else if (newVal === true && oldVal === false) {
      await clickButton();

      emits("update:modelValue", false);
    }
  }
);

const isDisabled = computed(() => {
  return steps.value !== BUTTON_STEPS.READY;
});

const isTextDisplayed = computed(() => {
  return (
    steps.value === BUTTON_STEPS.READY || steps.value === BUTTON_STEPS.ENDED
  );
});

const buttonText = computed(() => {
  if (steps.value === BUTTON_STEPS.ENDED) return "Terminé";
  else return props.textConfirm ?? "Confirmer";
});
</script>

<template>
  <button class="load__button relative" v-bind="$attrs"
    :class="[reverse ? 'button_border__default reverse-disabled' : 'button__default default-disabled']"
    :disabled="isDisabled || Boolean($attrs.disabled)" @click="clickButton" @keydown.enter.prevent="clickButton" >
    <span class="load__item" v-if="!nameIcon"
      :class="[isTextDisplayed ? 'opacity-100 whitespace-nowrap' : 'opacity-0 whitespace-nowrap']">{{ buttonText
      }}</span>
    <div class="load__item flex gap-2 items-center" v-if="nameIcon" :class="[isTextDisplayed ? 'opacity-100 whitespace-nowrap' : 'opacity-0 whitespace-nowrap', props.reverseIcon ? 'flex-row-reverse' : '']">
      <SvgIcon class="cursor-pointer w-[16px] h-[16px]" :name="nameIcon"></SvgIcon>
      <span class="body_med">{{ buttonText }}</span>
    </div>

    <span class="flex absolute load__item" :class="[!isTextDisplayed ? 'opacity-100' : 'opacity-0']">
      <span :class="[reverse ? 'reverse-dot-flashing' : 'dot-flashing']"></span>
    </span>
  </button>
</template>

<style scoped>
.load__item {
  transition: opacity 0.5s ease-in-out;
}

.load__button {
  @apply flex whitespace-nowrap justify-center items-center;
}

.load__button.default-disabled {
  &:disabled {
    background-color:  var(--grey-medium-light);
  }
}

.load__button.reverse-disabled {
  &:disabled {
    background: var(--white-main);
    border: 1px solid #D5D9F1;
  }
}

.dot-flashing {
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: var(--primary-blue-color);
  color: var(--primary-blue-color);
  animation: dot-flashing 1s infinite linear alternate;
  animation-delay: 0.5s;

  &::before,
  &::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 0;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: var(--primary-blue-color);
    color: var(--primary-blue-color);
    animation: dot-flashing 1s infinite alternate;
  }

  &::before {
    left: -15px;
    animation-delay: 0s;
  }

  &::after {
    left: 15px;
    animation-delay: 1s;
  }
}

.reverse-dot-flashing {
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: var(--primary-blue-color);
  color: var(--primary-blue-color);
  animation: reverse-dot-flashing 1s infinite linear alternate;
  animation-delay: 0.5s;

  &::before,
  &::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 0;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: var(--primary-blue-color);
    color: var(--primary-blue-color);
    animation: reverse-dot-flashing 1s infinite alternate;
  }

  &::before {
    left: -15px;
    animation-delay: 0s;
  }

  &::after {
    left: 15px;
    animation-delay: 1s;
  }
}

@keyframes dot-flashing {
  0% {
    background-color: #D5DAEF;
  }

  50%,
  100% {
    background-color: #EDF0FA;
  }
}

@keyframes reverse-dot-flashing {
  0% {
    background-color: #82C3FA;
  }

  50%,
  100% {
    background-color: #D5DAEF;
  }
}
</style>
